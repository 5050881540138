.stage {
  &.simple {
    min-height: 45rem;

    @include media-breakpoint-down(md) {
      min-height: auto !important;
    }

    .row {
      flex-grow: 1; // without this if there is a short text the content will be too narrow
    }

    .row {
      &.mirrored {
        // text-align: right;

        .stage-text {
          padding-left: 10%;

          @include media-breakpoint-down(lg) {
            padding-left: 0;

            text-align: initial;
          }
        }
      }

      &:not(.mirrored) {
        .stage-text {
          padding-right: 10%;

          @include media-breakpoint-down(lg) {
            padding-right: 0;

            text-align: initial;
          }
        }
      }
    }
  }
}
