.link {
  font-size: $font-size-input-md;       // share the same size as buttons
  font-weight: $font-weight-bold;

  display: flex;

  transition: color 0.15s ease-in-out;

  align-items: center;

  &-sm {
    font-size: $font-size-input-sm;   // share the same size as buttons
  }

  &-lg {
    font-size: $font-size-input-lg;   // share the same size as buttons
  }

  &.mirrored {
    flex-direction: row-reverse;
  }

  // &:hover {
  //   text-decoration: underline;
  // }

  &-light {
    &:hover,
    &:focus,
    &:active {
      color: shade-color($light, 25%);
    }
  }

  &-divider {
    &::after {
      display: inline-block;

      width: 1px;
      height: 12px;
      margin: 0 10px;

      content: '';

      background-color: currentColor;
    }

    &:last-child {
      &::after {
        display: none;
      }
    }
  }

  &-inline {
    display: inline-flex;

    margin-right: 30px;

    &:last-child {
      margin-right: 0;
    }
  }

  svg {
    transition: transform 0.15s ease-in-out;

    &.bi-chevron-left {
      .bi-chevron-left-arrow {
        transform: rotate(180deg);
        transform-origin: center;
      }
    }
  }

  &:hover,
  &:focus,
  &:active {
    svg {
      &.bi-chevron-right {
        transform: translateX(3px);
      }

      &.bi-chevron-left {
        transform: translateX(-3px);
      }

      &.bi-arrow-up-right {
        transform: translate(3px, -3px);
      }
    }
  }

  &-primary {
    font-size: rem(18px);
    font-weight: $font-weight-black;

    text-transform: uppercase;

    color: $brand-tertiary;

    svg {
      .bi-chevron-right-square,
      .bi-chevron-left-square {
        color: $primary;
      }

      .bi-chevron-right-arrow,
      .bi-chevron-left-arrow {
        color: $white;
      }
    }

    &:hover,
    &:focus,
    &:active {
      color: shade-color($brand-tertiary, 20%) !important;
    }
  }

  &-secondary {
    font-weight: $font-weight-bold;

    color: $brand-primary !important;

    svg {
      .bi-chevron-right-square,
      .bi-chevron-left-square {
        color: transparent;
      }

      .bi-chevron-right-arrow,
      .bi-chevron-left-arrow {
        color: $primary;
      }
    }

    &:hover,
    &:focus,
    &:active {
      color: shade-color($primary, 20%) !important;
    }
  }

  &-light {
    svg {
      .bi-chevron-right-square,
      .bi-chevron-left-square {
        color: $white;
      }

      .bi-chevron-right-arrow,
      .bi-chevron-left-arrow {
        color: $primary;
      }
    }
  }

  &-secondary,
  // &-light,
  &-dark {
    font-size: rem(20px);
    font-weight: 300;

    color: $body-color;

    &:hover,
    &:focus,
    &:active {
      color: shade-color($body-color, 50%);
    }
  }

  &-light {
    font-size: rem(18px);
    font-weight: 900;

    text-transform: uppercase;
  }
}

// .bg_color_second {
//   .link {
//     color: $white;
//   }
// }
