.hotspot {
  &-container {
    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  &-dots {
    position: absolute;
    z-index: 0;

    inset: 0;

    .nav {
      &-link {
        position: absolute;

        width: 20px;
        height: 20px;
        padding: 0;

        transform: translate(-50%,-50%);

        border-radius: 50%;
        background-color: $primary;

        @include media-breakpoint-up(md) {
          width: 26px;
          height: 26px;
        }

        &:hover,
        &:focus,
        &:active {
          &::before {
            display: none;
          }
        }

        &.active {
          width: 32px;
          height: 32px;

          @include media-breakpoint-up(md) {
            width: 42px;
            height: 42px;

            &::after {
              width: 62px !important;
              height: 62px !important;
            }
          }

          &::after {
            position: absolute;

            width: 42px;
            height: 42px;

            content: '';
            transform: translate(-50%,-50%);

            border: 2px solid $primary;
            border-radius: 50%;
          }
        }
      }
    }

    &-container {
      position: relative;
    }
  }
}
