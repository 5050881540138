
$brand-primary: #c6252a;
$brand-secondary: #b3b3b5;
$brand-tertiary: #565656;

$brand-white: #fff;
$brand-black: #000;

$brand-light: #f4f3f2;
$brand-dark: #333;

$brand-success: #28a745;
$brand-danger: #dc3545;
$brand-warning: #ffc107;
$brand-info: #17a2b8;
