.fact-sheet {
  &.horizontal {
    .g-5 {
      --bs-gutter-y: 5rem;
    }

    .card {
      &-horizontal {
        display: flex;
        // align-items: center;
      }

      &-image {
        flex-shrink: 0;
      }

      &-info {
        display: flex;
        flex-direction: column;

        justify-content: center;
      }

      .headline {
        margin-bottom: 0;
      }
    }
  }
}
