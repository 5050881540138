// .bg_color_second {
//   .headline {
//     color: $white;
//   }
// }

// h1.headline {
//   text-transform: uppercase;
// }


.headline {
  @include media-breakpoint-down(md) {
    br {
      display: none; // a dirty hack to make headlines look ok on mobile
    }
  }
}
