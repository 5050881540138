.menu-section {
  &.on-page {
    display: flex;
    overflow: auto;

    .nav {
      display: flex;

      margin: 0 auto;

      flex-wrap: nowrap;

      &-item {
        flex-shrink: 0;
      }

      &-link {
        font-weight: 900;
        // margin-right: rem(12px);  // set up margins to fix underlines
        // margin-left: rem(12px);   // set up margins to fix underlines

        padding-right: rem(12px);
        padding-left: rem(12px);

        transition: color 0.15s ease-in-out;
        text-transform: uppercase;

        color: transparentize($body-color, 0.5);

        &:hover,
        &:focus,
        &:active {
          color: $body-color;
        }
      }
    }
  }
}
