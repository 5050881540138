// .bg_color_second,
.bg_color_primary,
.bg_color_one,
// .bg_color_two,
// .bg_color_three,
// .bg_color_four,
// .bg_color_five,
// .bg_color_six,
// .bg_color_seven,
.bg_color_main_dark {
  hr:not(.color-static),
  .preline:not(.color-static),
  .headline:not(.color-static),
  .teaser:not(.color-static),
  // .link:not(.color-static),
  [class|='form']:not(.color-static):not(.form-floating):not(.is-invalid) {
    color: $white !important;
  }

  .teaser:not(.color-static) {
    .text-sm {
      color: $white;
    }
  }

  table {
    th,
    tr {
      color: $white !important;
      border-color: $white !important;
    }
  }

  legend {
    color: $white !important;
  }

  // .link:not(.color-static) {
  //   &:hover,
  //   &:focus,
  //   &:active {
  //     color: shade-color($white, 10%) !important;
  //   }
  // }

  .accordion:not(.color-static) {
    .accordion,
    .accordion-flush {
      &-button,
      &-body {
        color: $white !important;
      }

      &-button {
        &::after {
          background-image: escape_svg(url('data:image/svg+xml,<svg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 16 16\' fill=\'#{$white}\'><path fill-rule=\'evenodd\' d=\'M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z\'/></svg>'));
        }
      }
    }

    &.simple {
      .accordion,
      .accordion-flush {
        &-button {
          &::before,
          &::after {
            background-color: $white;
          }
        }
      }
    }
  }

  .investment-calculator {
    &-type,
    &-label {
      color: $white !important;
    }

    &-range {
      &::-webkit-slider-thumb {
        background-color: $primary;
      }
    }

    &-range {
      &::-moz-range-thumb {
        background-color: $primary;
      }
    }

    &-range {
      &::-ms-thumb {
        background-color: $primary;
      }
    }

    &-field {
      span {
        color: $white;
      }
    }

    .chart {
      [role='region'] {
        > g {
          fill-opacity: 1;
        }
      }
    }
  }

  .performance-chart {
    .amcharts-range-selector-period-button {
      color: $white;
    }

    .chart {
      [role='region'] {
        > g {
          fill-opacity: 1;
        }
      }
    }
  }

  .investment-quiz {
    &-step {
      &-heading {
        color: $white;
      }
    }
  }

  .fact-sheet {
    a {
      color: $white;
    }
  }

  .text-and-bullets {
    .list {
      li {
        color: $white;

        &::before {
          color: $white;
        }

        svg {
          color: $white;
        }
      }
    }
  }

  .breadcrumbs {
    .breadcrumb-item {
      color: $white;

      &::before {
        color: $white;
      }

      a {
        color: $white;

        &:hover,
        &:focus,
        &:active {
          color: $primary;
        }
      }
    }
  }

  .facts-and-bullets {
    &.standard {
      .bullets {
        &-item {
          color: $white;
        }
      }
    }
  }

  .footer {
    &.contact {
      .link {
        &-inline {
          border: 1px solid $white;

          &:hover,
          &:focus,
          &:active {
            color: $secondary !important;
            background-color: $white;
          }
        }
      }

      .footer-contact-info {
        &-icon {
          svg {
            color: $white;
          }
        }

        &-link {
          color: $white;
        }
      }
    }
  }

  .timeline {
    &.timeline-slider {
      .timeline {
        &-line {
          &-item {
            color: $white !important;

            &::before {
              background-color: $white !important;
            }
          }

          &-marker {
            &::before {
              background-color: $white !important;
            }

            &::after {
              border-color: $white !important;
            }
          }
        }

        &-date {
          color: $white !important;
        }
      }
    }
  }

  .menu-section {
    &.on-page {
      .nav-link {
        color: $white !important;

        &::before {
          background-color: $white;
        }
      }
    }
  }

  // h1.headline:not(.color-static),
  // .h1.headline:not(.color-static),
  // h4.headline:not(.color-static),
  // .h4.headline:not(.color-static) {
  //   color: $primary !important;
  // }

  // h2.headline:not(.color-static),
  // .h2.headline:not(.color-static) {
  //   color: $secondary !important;
  // }

  .display-1.headline:not(.color-static),
  .display-2.headline:not(.color-static),
  .display-3.headline:not(.color-static),
  .display-4.headline:not(.color-static) {
    color: $white !important;
  }

  // .display-4.headline:not(.color-static) {
  //   color: $primary !important;
  // }

  // .display-3.headline:not(.color-static) {
  //   color: $secondary !important;
  // }

  // .preline:not(.color-static) {
  //   color: $primary !important;
  // }


  .teaser ul,
  ul.list,
  .teaser ol,
  ol.list {
    li {
      &::before {
        color: $primary  !important;
      }
    }
  }

  .social-media {
    .link {
      color: $white !important;

      &:hover,
      &:focus,
      &:active {
        color: shade-color($light, 25%) !important;
      }
    }
  }

  .block-items {
    .link,
    a {
      color: $white !important;

      &:hover,
      &:focus,
      &:active {
        color: shade-color($light, 25%) !important;
      }
    }
  }

  .footerlinks {
    .link,
    a {
      color: $white !important;

      &:hover,
      &:focus,
      &:active {
        color: shade-color($light, 25%) !important;
      }
    }
  }
}

.centered {
  > .container,
  // TAI banner fix 
  img + .container {
    > .row {
      [class|='col'] {
        .preline {
          text-align: center;

          &::after {
            left: 50%;

            transform: translateX(-50%);
          }
        }

        .headline {
          text-align: center;
        }

        .teaser {
          text-align: center;
        }

        .links {
          justify-content: center;
          @include media-breakpoint-down(md) {
            align-items: center;
          }
        }
      }
    }
  }

  .card {
    &-image {
      text-align: center;
    }

    .preline {
      text-align: center;

      &::after {
        left: 50%;

        transform: translateX(-50%);
      }
    }

    .headline {
      text-align: center;
    }

    .teaser {
      text-align: center;
    }

    .links {
      justify-content: center;
      @include media-breakpoint-down(md) {
        align-items: center;
      }
    }
  }

  .fact-sheet {
    &-item {
      img {
        margin: 0 auto;
      }
    }
  }
}

// .bg_color_one {

// }
