.teaser-and-image {
  &.large {

    .container {
      position: relative;
    }

    // .row {
    //   &.mirrored {
    //     .order-2 { // text
    //       @include media-breakpoint-up(md) {
    //         padding-right: 32px;
    //       }
    //     }
    //   }

    //   &:not(.mirrored) {
    //     .order-2 { // text
    //       @include media-breakpoint-up(md) {
    //         padding-left: 32px;
    //       }
    //     }
    //   }
    // }

    .row {
      &.mirrored {
        .order-2 { // text
          @include media-breakpoint-up(lg) {
            padding-right: 10%;
          }
        }
      }

      &:not(.mirrored) {
        .order-2 { // text
          @include media-breakpoint-up(lg) {
            padding-left: 10%;
          }
        }
      }
    }
  }
}
