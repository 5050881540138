.menu-pages-full-width {
  overflow: visible !important;  // to prevent inner srolling from .container-fluid

  .navbar {
    &-brand {
      @include media-breakpoint-up(xxl) {
        // to make the left spacing the same as right
        margin-left: 16px;
        padding-left: $navbar-nav-link-padding-x;
      }
    }
  }
}
