// .bg_color_second {
//   .teaser {
//     color: $white;
//   }
// }

.teaser {
  position: relative;

  p:last-child:not(:only-child),
  p:last-of-type:not(:only-child) {
    margin-bottom: 0;
  }
}

.teaser ul,
ul.list,
.teaser ol,
ol.list {
  position: relative;

  padding-left: 1rem;

  list-style: none; /* Remove default bullets */

  li {
    margin-bottom: $size-xs;
    padding-left: rem(16px);

    &:last-child {
      margin-bottom: 0;
    }

    &::before {
      font-family: auto;
      font-size: 16px;
      font-weight: bold; /* If you want it to be bold */
      line-height: 1;

      position: absolute;
      left: 0.5rem;

      display: inline-block; /* Needed to add space between the bullet and the text */

      width: 13px;
      height: 13px;

      content: '';
      // content: '\2022';  /* Add content: \2022 is the CSS Code/unicode for a bullet */
      transform: translateX(-100%) translateY(8px);
      // margin-right: 1rem; /* Also needed for space (tweak if needed) */
      // margin-left: -2rem;
      text-align: right;

      color: $primary; /* Change the color */
      background-color: $primary;
    }
  }

  &-icon {
    padding-left: 30px;

    li {
      &:before {
        left: 1.5rem;

        width: 24px;
        height: 24px;

        content: '';

        background-color: transparent;
        background-image: url('../../../Images/svg/checked.svg');
        background-size: cover;

        filter: contrast(0.4); // faking secondary color for NFS
      }
    }
  }
}

.teaser ol,
ol.list {
  counter-reset: list-number;

  li {
    counter-increment: list-number;

    &::before {
      font-family: 'MuseoSans';
      // font-family: $font-family-secondary;
      font-size: rem(16px);
      // font-weight: $font-weight-secondary;
      font-weight: $font-weight-black;

      width: auto;

      content: counter(list-number)'.';
      transform: translateX(-100%) translateY(7px);

      background-color: transparent;
    }
  }
}
