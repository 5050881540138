.preline {
  font-size: rem(18px);
  font-weight: $font-weight-black;

  position: relative;

  display: flex;

  margin-bottom: 16px;
  // text-transform: uppercase;

  hyphens: initial;     // excluding from global styling to use &shy;

  color: $brand-primary;

  // align-items: center;

  &::before {
    display: inline-block;

    width: 22px;
    height: 22px;
    margin-right: 8px;

    content: '';

    background-image: url('../../../Images/svg/preline.svg');
  }
}

// .bg_color_second {
//   .preline {
//     color: $white;
//   }
// }
