.teaser-and-image {
  &.banner {
    .preline,
    .headline,
    .teaser,
    .links {
      position: relative;
    }
  }
}
