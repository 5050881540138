.contact-info {
  position: fixed;
  top: 50%;
  right: 0;

  transform: translateY(-50px) translateX(100%);

  &-item {
    position: absolute;
    top: 0;

    display: flex;

    width: 420px;

    transition: transform 0.5s ease-in-out;
    transform: translateX(0);

    &.active {
      transform: translateX(-100%);
    }
  }

  &-toggler {
    position: absolute;
    left: -50px;

    width: 50px;
    height: 50px;

    border: none;
    background-color: $primary;

    flex-shrink: 0;
    appearance: none;
  }

  &-icon {
    img {
      width: 22px;
      height: 22px;

      object-fit: contain;
    }
  }

  &-content {
    font-size: 14px;
    font-weight: 300;
    line-height: 16px;

    display: flex;

    padding: 20px;

    color: $black;
    background-color: $white;
  }

  &-image {
    img {
      max-width: 100px;
      margin-right: 20px;
    }
  }
}
