.timeline {
  &.timeline-slider {
    .timeline {
      &-card {
        &::before {
          display: none;
        }

        .row {
          &.mirrored {
            // text-align: right;

            // .order-1 { // text
            //   padding-left: 10%;

            //   @include media-breakpoint-down(lg) {
            //     padding-left: calc(var(--bs-gutter-x) * 0.5);

            //     text-align: initial;
            //   }
            // }

            .order-0 {
              img {
                margin-right: auto;
                margin-left: 0;
              }
            }
          }

          &:not(.mirrored) {
            // .order-1 { // text
            //   padding-right: 10%;

            //   @include media-breakpoint-down(lg) {
            //     padding-right: calc(var(--bs-gutter-x) * 0.5);

            //     text-align: initial;
            //   }
            // }

            .order-0 {
              img {
                margin-right: 0;
                margin-left: auto;
              }
            }
          }
        }
      }

      &-line {
        position: relative;
        z-index: 1;
        left: 0;

        display: flex;
        overflow-x: auto;

        width: auto;
        margin-bottom: 0;
        padding-right: 15px;
        padding-bottom: 20px;
        // fix for not visibe text
        padding-left: 35px;

        transform: none;

        background-color: transparent;

        flex-wrap: nowrap;
        // fix for not visible text
        @include media-breakpoint-up(xl) {
          overflow-x: visible;
          // to center content as we have margin-left: -55px on date

          margin-right: -55px;
          padding-left: 15px;
        };

        &-wrapper {
          display: flex;

          justify-content: center;
        }

        &-item {
          position: relative;
          // min-width: 100px;

          width: 105px;

          list-style-type: none;

          transition: opacity 0.15s ease-in-out;

          opacity: 0.3;

          &:hover,
          &:focus,
          &:active {
            opacity: 1;
          }

          &.highlighted {
            opacity: 1;
          }

          &.active {
            color: $primary;

            &::before {
              background-color: $primary;
            }

            .timeline-line-marker {
              &::before {
                background-color: $primary;
              }

              &::after {
                opacity: 1;
              }
            }
          }

          &:last-child {
            &::before {
              display: none;
            }
          }

          &::before {
            position: absolute;
            right: 0;
            bottom: 7px;
            left: 0;

            display: block;

            height: 2px;

            content: '';

            background-color: $dark;
          }
        }

        &-trigger {
          display: flex;
          // position: relative;

          width: 105px;
          height: 100%;
          padding: 16px 0 24px 0;

          color: inherit;
          border: none;
          background-color: transparent;

          align-items: flex-end;
          appearance: none;
        }

        &-marker {
          &::before,
          &::after {
            position: absolute;
            right: 0;
            bottom: 8px;
            left: 0;

            display: block;

            content: '';
            transform: translateY(50%);
            pointer-events: none;

            border-radius: 50%;
          }

          &::before {
            width: 12px;
            height: 12px;

            transition: background-color 0.15s ease-in-out;

            background-color: $dark;
          }

          &::after {
            left: -3px; // 6px / 2

            width: 18px;  // ::before + 6px
            height: 18px; // ::before + 6px

            transition: opacity 0.15s ease-in-out;

            opacity: 0;
            border: 2px solid $primary;
            background-color: transparent;
          }
        }

        &-date {
          // display: inline-block;
          font-size: rem(14px);
          font-weight: 300;

          display: block;

          width: 115px;
          // transform: translateX(-50%);
          margin-left: -55px;

          hyphens: auto;
        }
      }
    }
  }
}
