.stage {
  &-img {
    @include media-breakpoint-down(lg) {
      position: relative !important;

      width: 100vw !important;
      // width: auto !important;
      max-width: 100vw;
      height: 50vh !important;
      margin-right: -50vw;
      margin-left: -50vw;
    }
  }
}
