.scroll-transition {
  &.full-width {
    .scroll-transition-media-container {
      position: absolute;

      width: 100%;
      height: 100%;

      inset: 0;
      object-fit: cover;
      object-position: center;
      @include media-breakpoint-down(lg) {
        position: relative !important;

        width: auto !important;
        // max-width: 100vw;
        // margin-right: -50vw;
        // margin-left: -50vw;
      }
    }
  }
}
